import React from "react"
import Axios from "axios"

const getMdUrl = async (pck,lang, country, fileType,avc,version) => {

    var config = {
        method: 'get',
        url: `${process.env.GATSBY_API_URL}/api/nebula/v1/isc/i18n/policy?country=${country}&fileType=${fileType}&version=${version}`,
        headers: {
            'X-NR-PackageName': pck,
            'X-NR-LANG': lang,
            'X-NR-AppVersionCode': avc
        }
    };
    let mdURL = ''
    let getMdHtml = await Axios(config).then(function (data) {
        mdURL = data.data.data.url
    })


    return mdURL
}

export default getMdUrl