import React, { useContext, useState, useEffect } from "react"
import Axios from "axios"
import MdWrapper from "@/components/MarkdownWrap"
import getMdUrl from '@/utils/getMdUrl.js'
import getQueryVariable from '@/hooks/getQueryVariable'
function Index(props) {
  const [markdown, setMarkdown] = useState(null)

  const getMdText = async(lang,country,pck,avc,version) => {
    let filetype=''
    if(props.type=='pp'){
        filetype=1
    }else if(props.type=='tos'){
        filetype=2
    }
    let mdURL=await getMdUrl(pck,lang,country,filetype,avc,version)
    Axios.get(mdURL).then(function(data) {
      setMarkdown(data.data)
    })
  }
 
  useEffect(() => {
    let lang = getQueryVariable('lang')
    let country = getQueryVariable('country')
    let pck = getQueryVariable('package')
    let avc = getQueryVariable('avc')
    let version = getQueryVariable('version')
    getMdText(lang,country,pck,avc,version)
  }, [])

  return (
        <MdWrapper md={markdown} stl={{ marginTop: 0 }} />
  )
}

export default Index
